import * as React from 'react';
import * as styles from '../pages/index.module.scss';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';

import Brand from '../components/brand';
import Button from '../components/button';
import EmbedButton from '../components/embed-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../components/v2/footer';
import Header from '../components/v2/header';
import SEO from '../components/seo';
import Section from '../components/section';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { faStar } from '@fortawesome/pro-solid-svg-icons';

const Logo = ({ id }) => {
  switch (id) {
    case 'shopify':
      return (
        <svg
          width="110"
          height="125"
          viewBox="0 0 110 125"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M95.9 23.9c-.1-.6-.6-1-1.1-1s-9.3-.2-9.3-.2-7.4-7.2-8.1-7.9-2.2-.5-2.7-.3c0 0-1.4.4-3.7 1.1-.4-1.3-1-2.8-1.8-4.4-2.6-5-6.5-7.7-11.1-7.7-.3 0-.6 0-1 .1-.1-.2-.3-.3-.4-.5C54.7.9 52.1-.1 49 0c-6 .2-12 4.5-16.8 12.2-3.4 5.4-6 12.2-6.8 17.5-6.9 2.1-11.7 3.6-11.8 3.7-3.5 1.1-3.6 1.2-4 4.5-.3 2.5-9.5 73-9.5 73l76.4 13.2 33.1-8.2c-.1-.1-13.6-91.4-13.7-92m-28.7-7.1c-1.8.5-3.8 1.2-5.9 1.8 0-3-.4-7.3-1.8-10.9 4.5.9 6.7 6 7.7 9.1m-10 3.1c-4 1.2-8.4 2.6-12.8 3.9 1.2-4.7 3.6-9.4 6.4-12.5 1.1-1.1 2.6-2.4 4.3-3.2 1.8 3.5 2.2 8.4 2.1 11.8M49.1 4q2.1 0 3.6.9c-1.6.9-3.2 2.1-4.7 3.7-3.8 4.1-6.7 10.5-7.9 16.6-3.6 1.1-7.2 2.2-10.5 3.2C31.7 18.8 39.8 4.3 49.1 4"
            fill="#95BF47"
          />
          <path
            d="M94.8 22.9c-.5 0-9.3-.2-9.3-.2s-7.4-7.2-8.1-7.9c-.3-.3-.6-.4-1-.5V124l33.099-8.2s-13.5-91.3-13.6-92c-.1-.5-.6-.9-1.1-.9"
            fill="#5E8E3E"
          />
          <path
            d="m58 39.9-3.8 14.4s-4.3-2-9.4-1.6c-7.5.5-7.5 5.2-7.5 6.4.4 6.4 17.3 7.8 18.3 22.9.7 11.9-6.3 20-16.4 20.6-12.2.8-18.9-6.4-18.9-6.4l2.6-11s6.7 5.1 12.1 4.7c3.5-.2 4.8-3.1 4.7-5.1-.5-8.4-14.3-7.9-15.2-21.7-.7-11.6 6.9-23.4 23.7-24.4 6.5-.5 9.8 1.2 9.8 1.2"
            fill="#fff"
          />
        </svg>
      );
    case 'squarespace':
      return (
        <svg
          width="125"
          height="102"
          viewBox="0 0 125 102"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="m26.155 54 39.497-39.981a14.04 14.04 0 0 1 10.06-4.21c3.803 0 7.374 1.492 10.055 4.208l3.077 3.115L95 10.901l-3.077-3.115C87.6 3.41 81.843 1 75.709 1c-6.13.001-11.888 2.411-16.211 6.789L20 47.769z"
            fill="#000"
          />
          <path
            d="m79 27.3-6.185-6.223L33.132 61c-5.573 5.606-14.64 5.607-20.211.003-5.573-5.606-5.573-14.729 0-20.335L47.164 6.221 40.978 0 6.737 34.446c-8.983 9.037-8.983 23.742 0 32.777C11.081 71.593 16.864 74 23.024 74c6.161 0 11.948-2.41 16.292-6.779zm39.262 7.476C113.92 30.406 108.136 28 101.973 28c-6.161.001-11.948 2.408-16.291 6.779L46 74.7l6.182 6.222L91.867 41c5.574-5.606 14.64-5.605 20.211-.002 2.695 2.711 4.179 6.32 4.179 10.166s-1.484 7.457-4.179 10.168L77.837 95.78l6.185 6.22 34.24-34.445c8.984-9.037 8.984-23.742 0-32.779"
            fill="#000"
          />
          <path
            d="M98.846 48 59.349 87.981c-5.548 5.613-14.571 5.616-20.116.002l-3.077-3.115L30 91.099l3.077 3.115C37.4 98.59 43.157 101 49.292 101c6.13-.001 11.887-2.411 16.211-6.789L105 54.231z"
            fill="#000"
          />
        </svg>
      );
    default:
      return null;
  }
};

const LandingPageTemplate = ({ pageContext, data }) => {
  const { metaTitle, metaDescription, title, subtitle, btnText, logo } = pageContext;

  const quizImage = getImage(data.quizImage);

  return (
    <>
      <SEO title={`${metaTitle}`} description={`${metaDescription}`} onIndex={true} />
      <Header btnTheme="outline" btnLabel="Start free trial" />
      <main className={styles.index}>
        <Section theme="white" style={{ padding: `5rem 0`, marginTop: `4.5rem` }}>
          {logo && (
            <div className="row justify-content-center">
              <div className="col-auto">
                <div className={styles.logos}>
                  <div className={styles.logoContainer}>
                    <div className={styles.logo} style={{ backgroundPosition: `0 -1216px` }}></div>
                  </div>
                  <div className={styles.logoContainer}>
                    {logo === 'squarespace' ? <Logo id="squarespace" /> : null}
                    {logo === 'shopify' ? <Logo id="shopify" /> : null}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-10 col-xl-9">
              <h1 className="color-black text-center" style={{ marginBottom: `2.5rem` }}>
                {title}
              </h1>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6 text-center">
              <h2
                className="text-body color-muted-foreground text-center"
                style={{ marginRight: `0` }}>
                {subtitle}
              </h2>
              <form method="get" action="https://ai.tryinteract.com/create">
                <div className={styles.inputContainer}>
                  <input
                    type="text"
                    placeholder="https://www.mysite.com"
                    name="website_url"></input>
                  <Button theme="primary" type="submit" size="xl" style={{ gap: '12px' }}>
                    Get started <FontAwesomeIcon icon={faArrowRight} />
                  </Button>
                </div>
              </form>
              <p className={styles.subtext}>
                Already know what you want? <Link to="/plans/">Start from scratch</Link>
              </p>
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ padding: `2rem 0 5rem 0` }}>
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-12 col-xl-10">
              <div className="row">
                <div className="col">
                  <p className="text-body color-black text-center">Trusted by leading businesses</p>
                </div>
              </div>
              <div className="row align-items-center justify-content-center justify-content-lg-between">
                <Brand id="gretchen-rubin" className={`col-auto ${styles.brand}`} />
                <Brand id="jennakutcher-color" className={`col-auto ${styles.brand}`} />
                <Brand id="melyssagriffin-color" className={`col-auto ${styles.brand}`} />
                <Brand id="redcross-color" className={`col-auto ${styles.brand}`} />
                <Brand id="atlassian" className={`col-auto ${styles.brand}`} />
                <Brand id="lush-color" className={`col-auto ${styles.brand}`} />
              </div>
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ padding: `2rem 0 5rem 0` }}>
          <div className="row">
            <div className="col-12 text-center">
              <p className="heading-lg color-black" style={{ marginBottom: '1rem' }}>
                Empowering businesses since 2014 with proven results:
              </p>
            </div>
          </div>
          <div
            className={`row no-gutters align-items-center justify-content-center ${styles.metrics}`}>
            <div className="col-md-4">
              <p className={styles.number}>1.2B</p>
              <p className={styles.label}>quiz impressions</p>
            </div>
            <div className="col-md-4">
              <p className={styles.number}>400M</p>
              <p className={styles.label}>quizzes completed</p>
            </div>
            <div className="col-md-4">
              <p className={styles.number}>85M</p>
              <p className={styles.label}>leads generated</p>
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ padding: `2rem 0 5rem 0` }}>
          <div
            className="row no-gutters align-items-stretch justify-content-center"
            style={{ borderRadius: '32px', overflow: 'hidden', background: `#edf0ff` }}>
            <div className="col-lg-6 col-xl-5 order-lg-1 text-md-center">
              <div className={styles.quizContainer}>
                <p
                  className="heading-xs-upper color-purple-500"
                  style={{ margin: '0', fontWeight: '700', fontSize: '14px' }}>
                  Take Quiz
                </p>
                <p
                  className="heading-lg color-primary-950 font-style-golos"
                  style={{ margin: `0`, marginTop: '1rem', fontWeight: '700' }}>
                  What quiz should you make for your business?
                </p>
                <p className="heading-sm color-primary-950" style={{ margin: `1rem 0 2rem 0` }}>
                  Find your perfect lead generation quiz. Unlock the power of a 40% conversion rate
                  that quizzes offer by finding the exact quiz type that will work for your
                  business.
                </p>
                <EmbedButton id="65414c9de4c0cb0014c537e5" disableCover={true}>
                  Find out now
                </EmbedButton>
              </div>
            </div>
            <div className="col-md-8 col-lg-6 col-xl-7 order-lg-0 text-center">
              <GatsbyImage image={quizImage} alt="Interact template screenshots" />
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ padding: `2rem 0 5rem 0` }}>
          <div className="row justify-content-center">
            <div className="col-auto d-none d-sm-block">
              <div className={styles.integrations}>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Klaviyo"
                    style={{ backgroundPosition: '0px -880px' }}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Zapier"
                    style={{ backgroundPosition: '0px -1720px' }}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Mailchimp"
                    style={{ backgroundPosition: '0px -960px' }}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Interact"
                    style={{ backgroundPosition: '0px -760px' }}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Convertkit"
                    style={{ backgroundPosition: '0px -240px' }}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Flodesk"
                    style={{ backgroundPosition: '0px -480px' }}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Constant Contact"
                    style={{ backgroundPosition: '0px -200px' }}></div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <h3 className="heading-xl color-black">Integrate quizzes with your existing tools</h3>
            </div>
            <div className="col-lg-6 col-xl-6 text-center">
              <p className="heading-sm">
                Identify the needs of your customers based on an assessment so they can see clearly
                how to begin working with your business.
              </p>
              <Button
                theme="outline"
                size="large"
                type="internal"
                href="/integrations"
                style={{ marginTop: '2rem' }}>
                Browse integrations
              </Button>
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ padding: `2rem 0 5rem 0` }}>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <h3 className="heading-xl color-black">Our customers love Interact quizzes</h3>
            </div>
          </div>
          <div className="row" style={{ marginTop: '3rem' }}>
            <div className="col-md-6 col-lg-4">
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">
                  THANK YOU! This has literally been on my list for years and this is going to make
                  it so easy to just get it out there.
                </p>
                <div className={styles.caption}>
                  <p className="text-body color-black">Kelsey K.</p>
                  <p className="text-body">Business Coach</p>
                </div>
              </div>
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">Oh my goodness this is good!</p>
                <div className={styles.caption}>
                  <p className="text-body color-black">Heather F.</p>
                  <p className="text-body">Marketing Agency</p>
                </div>
              </div>
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">
                  Such a clean design, heaps of integration options that I think my sutdents will
                  benefit the most from using Interact over competitors.
                </p>
                <div className={styles.caption}>
                  <p className="text-body color-black">Teago</p>
                  <p className="text-body">Business Coach</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">
                  This is so much fun, and so easy and intuitive.
                </p>
                <div className={styles.caption}>
                  <p className="text-body color-black">Kemya S.</p>
                  <p className="text-body">Digital Marketing</p>
                </div>
              </div>
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">
                  I found it really helpful in getting a head start on the quiz and felt like it
                  saved me a lot of time from having to manually build it from scratch.
                </p>
                <div className={styles.caption}>
                  <p className="text-body color-black">Lauren T.</p>
                  <p className="text-body">Interior Designer</p>
                </div>
              </div>
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">
                  I love it, and I am excited about using it for my client work as well as my lead
                  generation.
                </p>
                <div className={styles.caption}>
                  <p className="text-body color-black">Marie P.</p>
                  <p className="text-body">Entrepreneur</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 d-none d-lg-block">
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">
                  Creating personalized quizzes to generate leads for my offers was surprisngly
                  easy.
                </p>
                <div className={styles.caption}>
                  <p className="text-body color-black">Connor H.</p>
                  <p className="text-body">Ditigal Creator</p>
                </div>
              </div>
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">
                  I just made a quiz in 5 minutes. It's pretty cool. And I had zero ideas of what I
                  wanted going into it.
                </p>
                <div className={styles.caption}>
                  <p className="text-body color-black">Emily</p>
                  <p className="text-body">Travel</p>
                </div>
              </div>
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">
                  I am so impressed with this quiz! It really feels like you captured my brand...
                </p>
                <div className={styles.caption}>
                  <p className="text-body color-black">Christina C.</p>
                  <p className="text-body">Life Coaching</p>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section theme="purple500" style={{ padding: `5rem 0` }}>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <h3 className="heading-xl color-white">Get started today</h3>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xl-6 text-center">
              <p
                className="heading-sm color-white text-center"
                style={{ marginTop: '0', marginBottom: '2rem' }}>
                Use our AI quiz maker to create a beautiful quiz for your brand in a minute.
              </p>
              <Button theme="white" size="large" href="https://ai.tryinteract.com/create">
                Make a quiz - for free
              </Button>
            </div>
          </div>
        </Section>
      </main>
      <Footer />
    </>
  );
};

export default LandingPageTemplate;

export const pageQuery = graphql`
  query {
    quizImage: file(relativePath: { eq: "landing-quiz.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, transformOptions: { cropFocus: CENTER })
      }
    }
  }
`;
